/* eslint-disable react-hooks/exhaustive-deps */
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../components/Input";
import StepMovement from "../../components/StepMovement";
import Checkbox from "@mui/material/Checkbox";
import "./style.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../assets/icons/Icon.svg";
import Terms from "../../components/Terms";
import { convertAmtStringToNumber, formatCurrency } from "../../utils";
import bdown from "../../assets/icons/bdown.svg";
import axios from "axios";
import PaystackPop from "@paystack/inline-js";
import { parsePhoneNumber } from "libphonenumber-js";
import { ArrowBack } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

const Checkout = () => {
  const baseUrl = "https://enterprise.gricdbackend.com";
  // const baseUrl = "https://enterprise.gricdbackend.com";
  const methods = useForm();
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  const planInfo = state || {};

  useEffect(() => {
    if (!state) return navigate("/");
    if (state && state.data) {
      Object.entries(state.data).forEach(([key, value]) => {
        methods.setValue(key, value);
      });
      setChecked2(true);
      setChecked(state.data.checked);
    }
  }, []);

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  const handleChange2 = (e) => {
    setChecked2(e.target.checked);
  };
  const onSubmit = async (data) => {
    data = { ...data, checked };
    const params = {
      email: data.email.replace("%40", "@"),
      phone: data.phone.substring(1),
    };

    const addonsIds = () => {
      let idsArray = [];
      state.addonList?.map(({ count, _id }) => {
        for (let i = 0; i < count; i++) {
          idsArray.push(_id);
        }
      });
      return idsArray;
    };

    const calcPayload = {
      planId: state.id,
      duration: state.duration === "annualCost" ? "annually" : "monthly",
      number: state.totalMonths,
      addons: state.addonList.map(({ _id, count }) => ({
        qty: count,
        id: _id,
      })),
      // addons: addonsIds(),
    };

    setPageLoading(true);

    await axios
      .get(`${baseUrl}/v2/ecommerce/check`, { params })
      .then(async (res) => {
        if (res.data.email.available && res.data.phone.available) {
          await axios
            .post(`${baseUrl}/v2/ecommerce/calculate`, calcPayload)
            .then((res) => {
              setPageLoading(false);
              if (res.data.grandTotal === planInfo.totalPrice) {
                processPayment(data.email);
              }
            })
            .catch((err) => {
              console.log(err, "error");
              setPageLoading(false);
            });
        } else {
          setPageLoading(false);
          if (!res.data.email.available) {
            methods.setError("email", {
              type: "manual",
              message: "Email already exist",
            });
            document.getElementById("email").focus();
          }
          if (!res.data.phone.available) {
            methods.setError("phone", {
              type: "manual",
              message: "Phone already exist",
            });
            document.getElementById("phone").focus();
          }
        }
      })
      .catch((err) => {
        console.log(err, "error");
        setPageLoading(false);
      });
  };

  const accept = () => {
    setOpenModal(false);
    return setChecked2(true);
  };
  const decline = () => {
    setOpenModal(false);
    return setChecked2(false);
  };

  const proceed = async (response) => {
    setPageLoading(true);
    const { phone, email, first_name, last_name, company, password, address } =
      methods.getValues();
    const subPayload = {
      plan: planInfo.id,
      amountPaid: planInfo.totalPrice.toString(),
      paystack_ref: response,
      durationLength: planInfo.totalMonths,
      addons: planInfo.addonList.map(({ _id }) => _id),
      duration: state.duration === "annualCost" ? "annually" : "monthly",
      email,
      fullname: `${first_name} ${last_name}`,
      company_name: company,
      phone: phone.substring(1),
      password,
      address,
      countryCode: parsePhoneNumber(phone).countryCallingCode,
    };

    const prospectPayload = {
      email,
      fullname: `${first_name} ${last_name}`,
      phone: phone.substring(1),
    };

    await axios
      .post(`${baseUrl}/v2/ecommerce/subscribe`, subPayload)
      .then((res) => {
        setPageLoading(false);
        navigate("/success", {
          state: {
            success: true,
          },
        });
      })
      .catch((err) => {
        console.log(err, "error");
        setPageLoading(false);
      });

    await axios
      .post(`${baseUrl}/v2/ecommerce/ppc`, prospectPayload)
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const processPayment = (email) => {
    let key = process.env.REACT_APP_LIVE_KEY;
    const paystack = new PaystackPop();
    paystack.newTransaction({
      key,
      email,
      amount: planInfo.totalPrice * 100,
      onSuccess: (transaction) => {
        proceed(transaction.reference);
      },
    });
  };

  const goBack = () => {
    if (pageLoading) return;
    const planName = planInfo?.plan?.name;
    const pathName = planName.toLowerCase();
    const data = methods.getValues();
    navigate(`/addon/${pathName}`, {
      state: {
        ...state,
        data,
      },
    });
  };

  return (
    <div className="pd_checkout">
      <div className="cover_checkout">
        <StepMovement step={2} />
        <div className="bill_box">
          <ArrowBack onClick={goBack} className="mb-3 cursor" />
          <div className="address-addons">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <p className="title">Billing Adress</p>
                <div className="form-group-wrap col-gap">
                  <InputField
                    type="name"
                    name="first_name"
                    placeholder="Name"
                    label="First Name"
                    style={{
                      cursor: "pointer",
                    }}
                    autoComplete="off"
                    className="w-100"
                  />
                  <InputField
                    type="name"
                    name="last_name"
                    placeholder="Name"
                    label="Last Name"
                    style={{
                      cursor: "pointer",
                    }}
                    autoComplete="off"
                    className="w-100"
                  />
                </div>
                <InputField
                  type="email"
                  name="email"
                  placeholder="Email"
                  label="Email"
                  style={{
                    cursor: "pointer",
                  }}
                  autoComplete="off"
                />
                <div className="additional">
                  <InputField
                    type="password"
                    name="password"
                    placeholder="Password"
                    label="Password"
                    style={{
                      cursor: "pointer",
                    }}
                    autoComplete="off"
                  />
                  <p className="additional_text">
                    Strong passwords should be atleast 8 characters long with a
                    mix of upper case, lower case, number and special character
                  </p>
                </div>
                <InputField
                  type="text"
                  name="company"
                  placeholder="Company"
                  label="Company"
                  style={{
                    cursor: "pointer",
                  }}
                  autoComplete="off"
                />
                <div className="form-group-wrap col-gap">
                  {/* <SelectField
                  type="country"
                  name="country"
                  placeholder="country"
                  label="Country"
                  style={{
                    cursor: "pointer",
                  }}
                  autoComplete="off"
                  className="w-100"
                />

                <SelectField
                  type="region"
                  name="state"
                  placeholder="region"
                  label="Region"
                  style={{
                    cursor: "pointer",
                  }}
                  country={regionCountry}
                  className="w-100"
                /> */}
                  <InputField
                    type="text"
                    name="address"
                    placeholder="Address"
                    label="Address"
                    style={{
                      cursor: "pointer",
                    }}
                    autoComplete="off"
                    className="w-100"
                  />
                </div>
                <div className="form-group-wrap col-gap">
                  {/* <InputField
                    type="text"
                    name="postal_code"
                    placeholder="Postal Code"
                    label="Postal Code"
                    style={{
                      cursor: "pointer",
                      // width: "53%",
                    }}
                    autoComplete="off"
                    className="postal_code"
                  /> */}

                  <InputField
                    type="tel"
                    name="phone"
                    placeholder="08038232323"
                    label="Phone number"
                    className="phone_class"
                  />
                  <div className="w-100"></div>
                </div>

                {/* <div className="check_flex">
                  <Checkbox
                    checked={checked}
                    name="checked"
                    onChange={handleChange}
                  />
                  <p>My billing and shipping adress are the same</p>
                </div> */}

                <div className="submit_box">
                  <div className="check_flex">
                    <Checkbox
                      checked={checked2}
                      name="checked2"
                      onChange={handleChange2}
                      required
                    />
                    <p className="term">
                      I agree to the{" "}
                      <span onClick={() => setOpenModal(true)}>
                        Terms & Conditions
                      </span>
                    </p>
                  </div>

                  <button
                    className={`submit btn ${
                      pageLoading ? "btn_disabled" : ""
                    }`}
                  >
                    Pay <img src={Icon} alt="submit" />
                    <CircularProgress
                      size={"1.5rem"}
                      className="clr-brand_green loader"
                    />
                  </button>
                </div>
              </form>
            </FormProvider>

            <div className="cart-info">
              <div className="cover_checkout w-100">
                {/* <div className="bill_box"> */}
                <div className="">
                  <div className="each_pack">
                    <div className="top_pack">
                      <p>Package</p>
                    </div>
                    <div className="text_flex">
                      <p>
                        {planInfo?.plan?.name} /{" "}
                        {planInfo?.duration === "annualCost"
                          ? "Annually"
                          : "Monthly"}
                      </p>
                      <p>
                        {planInfo?.currency}{" "}
                        {formatCurrency(
                          convertAmtStringToNumber(
                            planInfo?.plan[planInfo?.duration].substring(1)
                          )
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="each_pack">
                    <div className="top_pack">
                      <p>Add-Ons</p>
                    </div>
                    <div className="text_flex">
                      <p>{planInfo?.addonList?.length} items in cart</p>
                      <p>
                        {planInfo?.currency}
                        {formatCurrency(planInfo?.totalAddon)}
                      </p>
                    </div>
                  </div>

                  <div className="each_pack sum">
                    <div className="top_pack">
                      <p>Check out Summary</p>
                    </div>
                    <div className="line"></div>
                    <div className="text_flex total">
                      <p>Total</p>
                      <p>
                        {planInfo?.currency}{" "}
                        {formatCurrency(planInfo?.totalPrice)}
                      </p>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>

            {openModal && (
              <Terms
                open={openModal}
                handleClose={() => setOpenModal(false)}
                accept={accept}
                decline={decline}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
