import active from "../../assets/icons/Content.svg";
import done from "../../assets/icons/_Step icon base.svg";
import undone from "../../assets/icons/undone.svg";
import "./style.scss";

const StepMovement = ({ step }) => {
  return (
    <div className="pd_step_movement">
      <div className="cover_step">
        <div className="each_step">
          <img src={step === 1 ? active : done} alt="" />
          <p className="green">Add-Ons</p>
        </div>

        <div className="line"></div>
        <div className="each_step">
          <img src={step === 2 ? active : step < 2 ? undone : done} alt="" />
          <p
            className={
              step === 2 ? "green active" : step - 2 < 0 ? "" : "green"
            }
          >
            Address/Summary
          </p>
        </div>

        <div className="line"></div>
        <div className="each_step">
          <img src={step === 3 ? done : undone} alt="" />
          <p
            className={
              step === 3 ? "green active" : step - 3 < 0 ? "" : "green"
            }
          >
            Payment
          </p>
        </div>
        {/* <div className="line"></div>
        <div className="each_step">
          <img
            src={step === 4 ? active : step - 4 < 0 ? undone : done}
            alt=""
          />
          <p
            className={
              step === 4 ? "green active" : step - 4 < 0 ? "" : "green"
            }
          >
            Payment
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default StepMovement;
