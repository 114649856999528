import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/icons/Logo.png";
import Hamburger from "../Hamburger";
import "./style.scss";
const Navbar = ({ executeScroll }) => {
  const [isNavOpen, setisNavOpen] = useState(false);
  const location = useLocation();
  const history = useNavigate();
  const handleClick = (view) => {
    if (location.pathname === "/addon" || location.pathname === "/checkout") {
      history("/");
      setTimeout(() => {
        executeScroll(view);
      }, 200);
    } else executeScroll(view);
  };
  return (
    <div className="pd_navbar ">
      <div className="cover_nav">
        <Link to="/">
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <Hamburger
          handleClick={() => {
            setisNavOpen(!isNavOpen);
          }}
        />
        <ul className={`nav_list ${isNavOpen ? "nav-opened" : ""}`}>
          {/* <li onClick={() => handleClick("serveRef")}>Services</li>
          <li onClick={() => handleClick("plansRef")}> Plans</li>
          <li>
            <li onClick={() => handleClick("faqRef")}> Contact Us</li>
          </li> */}
          <li className="add">
            <a href="http://enterprise.figorr.com/login">Login</a>/
            <a href="http://enterprise.figorr.com/signup">Register</a>
          </li>
        </ul>
        <div className="cover_log">
          <a href="http://enterprise.figorr.com/login">Login</a>/
          <a href="http://enterprise.figorr.com/signup">Register</a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
