import { useRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import AddOn from "./pages/AddOn";
import Checkout from "./pages/Checkout";
import Summary from "./pages/Checkout/summary";
import Footer from "./pages/Home/widgets/Footer";
function AllRoutes() {
  const refObj = {
    aboutUsRef: useRef(null),
    plansRef: useRef(null),
    contactUsRef: useRef(null),
    faqRef: useRef(null),
    serveRef: useRef(null),
  };

  const executeScroll = (ref) =>
    refObj[ref].current.scrollIntoView({ behavior: "smooth" });
  return (
    <BrowserRouter>
      <Navbar executeScroll={executeScroll} />
      <Routes>
        <Route exact path="/addon/:id" element={<AddOn />} />
        <Route exact path="/addon/" element={<AddOn />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/success" element={<Summary />} />
        <Route path="/*" element={<Navigate to="/addon" />} />
      </Routes>
      <Footer executeScroll={executeScroll} />
    </BrowserRouter>
  );
}
export default AllRoutes;
