/* eslint-disable no-unused-vars */
import "./style.scss";
import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import successIcon from "../../assets/icons/Check icon.png";
import ArrowBack from "@mui/icons-material/ArrowBack";
import StepMovement from "../../components/StepMovement";

const Summary = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!state) {
      navigate("/addon");
    }
  }, []);

  return (
    <React.Fragment>
      <div className="success" style={{ padding: "5rem 0" }}>
        <div style={{ marginBottom: "4.75rem" }}>
          <StepMovement step={3} />
        </div>
        <div className="success success_container">
          <img src={successIcon} alt="" style={{ marginBottom: "25px" }} />
          <p>Payment Successful</p>
          <a
            href="http://enterprise.figorr.com/login"
            target="_self"
            className="w-100"
          >
            <button
              className="pay btn back-to-checkout-btn"
              style={{ marginTop: "80px", marginBottom: "25px" }}
            >
              Login to Figorr Enterprise
            </button>
          </a>

          <a
            className="back back_to_web_text"
            href="https://www.figorr.com/"
            target="_self"
            rel="noopener noreferrer"
          >
            <ArrowBack />
            Go back to the website
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Summary;
