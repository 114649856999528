import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormLabel } from "@mui/material";
import InputErrorMsg from "./InputErrorMsg";
import "./style.scss";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
export default function SelectField({
  name,
  errMsg,
  className,
  required = false,
  selectOption = [],
  label,
  isLoading,
  isDisabled,
  handleCustomChange,
  placeholder,
  val,
  type,
  country
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const customSelect = () => {
    switch (type) {
      case "country":
        return (
          <>
            <Controller
              name={name}
              control={control}
              rules={{
                validate: (value) => !!value,
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <CountryDropdown
                  value={value}
                  name="country"
                  onChange={onChange}
                  style={{
                    width: "100%",
                    border: "1px solid #b7c3cd",
                    borderRadius: "4px",
                    padding: "15px",
                    fontSize: "12px",
                    color: "#404a57",
                    marginBottom: "8px",
                    marginRight: "16px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                />
              )}
            />
            {errors[name] && (
              <p className="error-message">This Field is required</p>
            )}
          </>
        );
      case "region":
        return (
          <>
            <Controller
              name={name}
              control={control}
              rules={{
                validate: (value) => !!value,
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <RegionDropdown
                  country={country}
                  value={value}
                  name="region"
                  onChange={onChange}
                  style={{
                    width: "100%",
                    border: "1px solid #b7c3cd",
                    borderRadius: "4px",
                    padding: "15px",
                    fontSize: "12px",
                    color: "#404a57",
                    marginBottom: "8px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                />
              )}
            />
            {errors[name] && (
              <p className="error-message">This Field is required</p>
            )}
          </>
        );
      default:
        return (
          <Controller
            control={control}
            name={name}
            rules={{ required: required ? "This field is required" : false }}
            render={({ field: { onChange, value = val, ref } }) => (
              <Select
                className="select-mui"
                ref={ref}
                value={value || ""}
                onChange={(e) => {
                  onChange(e.target.value);

                  handleCustomChange && handleCustomChange(e.target.value);
                }}
                disabled={isLoading || isDisabled}
                displayEmpty
                error={errors[name]}
              >
                {placeholder && (
                  <MenuItem className="menu-item placeholder" value={""}>
                    {placeholder}
                  </MenuItem>
                )}
                {selectOption.map((option, i) => {
                  return (
                    <MenuItem
                      key={`option-${i}`}
                      className="menu-item"
                      value={typeof option === "string" ? option : option.value}
                    >
                      {typeof option === "string" ? option : option.label}
                    </MenuItem>
                  );
                })}
                {selectOption.length === 0 && (
                  <MenuItem value={null} disabled className="center">
                    No Options
                  </MenuItem>
                )}
              </Select>
            )}
          />
        );
    }
  };
  return (
    <FormControl
      sx={{ m: "10px 0" }}
      className={`form-group select-group ${className}`}
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {customSelect()}
      {errMsg && errors[name] && (
        <InputErrorMsg errMsg={errors[name].message || errMsg} />
      )}
    </FormControl>
  );
}
