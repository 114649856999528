export function formatCurrency(amount) {
  return Number(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
}


export const convertAmtStringToNumber = (nairaAmount) => {
  return parseInt(nairaAmount.replace(/[^\d]/g, ""), 10);
};

export const extractNumber = (str) => {
  const regex = /[\d,]+/;
  const matches = str.match(regex);

  if (matches) {
    return parseInt(matches[0].replace(/,/g, ""), 10);
  }

  return null;
};