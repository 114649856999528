import footerlogo from "../../../../assets/icons/Logo.png";
import socialMedia from "../../../../assets/icons/instagram.svg";
import twitter from "../../../../assets/icons/twitter.svg";
import instagram from "../../../../assets/icons/Vector.svg";
import linkedin from "../../../../assets/icons/linkedin.svg";
import { Link } from "react-router-dom";
import "./style.scss";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const Footer = ({ executeScroll }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNewsLetter = async (e) => {
    e.preventDefault();
    setLoading(true);
    const hubspot_response = await submit_hubspot_form(email);
    setEmail("");
    setLoading(false);
    toast.success(hubspot_response.data.inlineMessage, {
      position: "top-right",
      theme: "colored",
    });
  };

  const submit_hubspot_form = async (email) => {
    const portalId = "24194137"; // example portal ID (not real)
    const formGuid = "3a09a411-4e91-4d53-8763-a88d0818b284"; // example form GUID (not real)
    const config = {
      // important!
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: "email",
            value: email,
          },
        ],
      },
      config
    );
    return response;
  };

  return (
    <footer className="pd_footer">
      <div className="footer_flex">
        <div className="each logoeach">
          <Link to="/">
            <img src={footerlogo} alt="logo" className="logo" />
          </Link>
          <p className="text">Go with Facts. Go with Figorr.</p>
        </div>
        <div className="each">
          <p className="section_title">Company</p>
          <a
            href="https://www.figorr.com/healthcare"
            target="_self"
            rel="noopener noreferrer"
          >
            <p>Our Clientele</p>
          </a>
          <a
            href="https://www.figorr.com/terms-conditions"
            target="_self"
            rel="noopener noreferrer"
          >
            <p>Terms and Conditions</p>
          </a>
          <a
            href="https://www.figorr.com/privacy-policy"
            target="_self"
            rel="noopener noreferrer"
          >
            <p>Privacy Policy</p>
          </a>
        </div>
        <div className="each">
          <p className="section_title">Get in Touch</p>
          <div className="links">
            <a
              href="https://www.figorr.com/demo-forms"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Contact Us
            </a>
            <div className="cover_img">
              <a
                href="https://www.linkedin.com/company/gricd/mycompany/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="linkedin" />
              </a>
              <a
                href="https://instagram.com/thegricd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="messanger" />
              </a>

              <a
                href="https://twitter.com/TheGricd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="twitter" />
              </a>
              <a href="javascript(0)" rel="noopener noreferrer">
                <img src={socialMedia} alt="linkedin" />
              </a>
            </div>
          </div>
        </div>
        <div className="each">
          <p className="section_title">Join Our Newsletter</p>
          <form action="" onSubmit={handleNewsLetter}>
            <input
              placeholder="Your Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              type="email"
            />
            <button disabled={loading}>
              <div className="flex">
                <span>Subscribe</span>
                {loading && (
                  <div className="ml-1">
                    <CircularProgress size="20px" color="info" />
                  </div>
                )}
              </div>
            </button>
          </form>
          <p className="update">
            * Will send you weekly updates for your better <br /> cold-chain
            management.
          </p>
        </div>
      </div>
      <div className="line"></div>
      <p className="copy">
        Copyright @Figorr {new Date().getFullYear()}. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
