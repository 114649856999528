import Feature from "./feature";
import "./style.scss";
import { FormProvider, useForm } from "react-hook-form";
import ellipse from "../../assets/icons/ellipse.svg";
import SelectField from "../../components/Input/SelectField";
import lefticon from "../../assets/icons/lefticon.svg";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  convertAmtStringToNumber,
  extractNumber,
  formatCurrency,
} from "../../utils";
import axios from "axios";
import StepMovement from "../../components/StepMovement";
import minus from "../../assets/icons/minus.png";
import add from "../../assets/icons/add.png";

const AddOn = () => {
  const methods = useForm();
  // const baseUrl = "https://gricd-africa.herokuapp.com/v2/enterprise";
  const baseUrl = "https://enterprise.gricdbackend.com/v2/ecommerce";
  const { state, pathname } = useLocation();
  // const { _id, pricings, currency, period } = state || {};
  const navigate = useNavigate();
  const [plansObj, setPlanObj] = useState({});
  const [id, setId] = useState(null);
  const [duration, setDuration] = useState("monthlyCost");
  const [totalPeriod, setTotalPeriod] = useState(1);
  const [showDropdown, setShowDropdown] = useState(null);
  const [currency, setCurrency] = useState("");
  const [data, setData] = useState([]);

  const dropdown = useRef(null);
  //closes the dropdown
  useEffect(() => {
    const plans = ["basic", "pro", "standard"];
    const paths = pathname.split("/");
    const hasPlanInUrl = paths.length === 3 && plans.includes(paths[2]);
    if (!hasPlanInUrl) {
      navigate("/addon");
    }
    axios.get(`${baseUrl}/pricings`).then((res) => {
      setData(res.data.pricings);
      const data = res.data.pricings?.reduce((obj, plan) => {
        obj[plan._id] = plan;
        return obj;
      }, {});
      setPlanObj(data);

      if (state && state.id) {
        const { id, currency, duration, addonList,totalMonths } = state;
        setCurrency(currency);
        setId(id);
        setDuration(duration);
        setAddOnList(addonList);
        setTotalPeriod(totalMonths)
      } else {
        setCurrency(res.data.pricings[0].monthlyCost.substring(0, 1));
        if (hasPlanInUrl) {
          const planName = paths[2];
          const planId = res.data.pricings.find(
            ({ name }) => name.toLowerCase() === planName
          )._id;
          setId(planId);
        } else setId(res.data.pricings[0]._id);
      }
    });

    const hide = (e) => {
      if (!dropdown.current?.contains(e.target)) {
        setShowDropdown(null);
      }
    };

    window.addEventListener("mousedown", hide);
    return () => {
      window.removeEventListener("mousedown", hide);
    };
  }, []);

  useEffect(() => {
    if (id === "62a593352a9ec30680c69435") {
      setDuration("monthlyCost");
      methods.setValue("duration", "monthlyCost");
      setTotalPeriod(1);
    }
  }, [id]);

  const onSubmit = (data) => {
    navigate("/checkout", {
      state: {
        addonList,
        id,
        totalPrice: totalPrice(),
        totalMonths: totalMonths(),
        currency,
        totalAddon: totalAddons(),
        plan: plansObj[id],
        duration,
        data: state?.data ? state.data : undefined,
      },
    });
  };
  const [addonList, setAddOnList] = useState([]);

  const totalAddons = () => {
    let period = totalPeriod;
    if (duration === "annualCost") period = totalPeriod * 12;
    return addonList?.reduce(
      (sum, addon) => sum + extractNumber(addon.price) * addon.count * period,
      0
    );
  };

  console.log(totalAddons(), "totalAddons");

  const addToList = (item) => {
    setAddOnList((prev) => {
      return [...prev, { ...item, count: 1 }];
    });
  };

  const decreaseAddon = (index) => {
    if (addonList[index].count === 1) return;
    const temp = [...addonList];
    temp[index] = {
      ...temp[index],
      count: temp[index].count - 1,
    };
    setAddOnList(temp);
  };

  const increaseAddon = (index) => {
    const temp = [...addonList];
    temp[index] = {
      ...temp[index],
      count: temp[index].count + 1,
    };
    setAddOnList(temp);
  };

  const handleCustomChange = (val) => {
    setId(val);
    setAddOnList([]);
  };

  const addAddons = plansObj[id]?.addons?.map(({ _id }) => _id);

  const btnIsDisabled = (id) => {
    // if (addAddons.includes(id)) return false;
    return addonList.some(({ _id }) => _id === id);
  };

  const totalMonths = () => {
    // if (duration === "annualCost") return totalPeriod * 12;
    return totalPeriod;
  };

  const totalPrice = () => {
    const number = (string) => {
      return Number(string.substring(1));
    };
    return (
      convertAmtStringToNumber(plansObj[id][duration]) * totalPeriod +
      totalAddons()
    );
  };

  function totalDevices() {
    return (
      extractNumber(plansObj[id].numberofDevices) +
      addonList.filter(({ _id }) => _id === addAddons[0]).length
    );
  }

  function totalUsers() {
    return (
      extractNumber(plansObj[id].assignedUser) +
      addonList.filter(({ _id }) => _id === addAddons[1]).length
    );
  }

  function rightClick() {
    if (id === "62a593352a9ec30680c69435") return;
    setTotalPeriod(totalPeriod + 1);
  }
  function leftClick() {
    if (id === "62a593352a9ec30680c69435") return;
    if (totalPeriod === 1) return;
    setTotalPeriod(totalPeriod - 1);
  }

  const removeAddon = (idx) => {
    let temp = [...addonList];
    temp.splice(idx, 1);
    setAddOnList(temp);
  };

  const totalAddon = (id) => {
    return addonList.reduce((total, _id) => {
      if (_id === id) {
        return total + 1;
      }
      return total;
    }, 0);
  };

  const getAddonTotal = (price, count) => {
    let period = totalPeriod;
    if (duration === "annualCost") period = period * 12;
    return formatCurrency(
      convertAmtStringToNumber(price.substring(1)) * count * period
    );
  };

  return (
    <React.Fragment>
      <div style={{ marginTop: "60px" }}>
        <StepMovement step={1} />
      </div>
      <div className="pd_add_on">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="cover_add section1">
              <p className="title">Add Ons</p>
              <div className="addon_flex">
                <div className="left">
                  <div>
                    {plansObj[id]?.addons?.map((item) => {
                      return (
                        <Feature
                          currency={currency}
                          addToList={addToList}
                          key={item._id}
                          item={item}
                          disabled={btnIsDisabled(item._id)}
                        />
                      );
                    })}
                  </div>
                </div>
                {Object.keys(plansObj).length > 0 && (
                  <div className="right">
                    <div className="cover_form_side">
                      <div className="form-group-wrap col-gap-1">
                        <SelectField
                          label="Plan"
                          name="plan"
                          selectOption={data
                            .slice(0, 3)
                            .map(({ name, _id }) => ({
                              label: name,
                              value: _id,
                            }))}
                          val={id}
                          handleCustomChange={handleCustomChange}
                        />

                        <SelectField
                          label="Duration"
                          name="duration"
                          selectOption={[
                            { label: "Annually", value: "annualCost" },
                            { label: "Monthly", value: "monthlyCost" },
                          ]}
                          val={duration}
                          handleCustomChange={(value) => setDuration(value)}
                          isDisabled={id === "62a593352a9ec30680c69435"}
                        />
                      </div>
                      <div className="details_box">
                        <div className="text_flex">
                          <p className="left_text">
                            Plan: {plansObj[id]?.name}
                          </p>
                          <p className="right_text">
                            {currency}
                            {formatCurrency(
                              convertAmtStringToNumber(
                                plansObj[id][duration].substring(1)
                              )
                            )}
                          </p>
                        </div>
                        <div className="text_flex">
                          <p className="left_text">
                            Duration:{" "}
                            {duration === "annualCost" ? "Annual" : "Monthly"}
                          </p>
                          <div className="right_box">
                            <img
                              src={minus}
                              className={`arr ${
                                id !== "62a593352a9ec30680c69435" && "cursor"
                              }`}
                              alt="left"
                              onClick={leftClick}
                            />
                            <div className="text_square">
                              <p>{totalPeriod}</p>
                            </div>
                            <img
                              src={add}
                              alt="right"
                              className={`arr rotate ${
                                id !== "62a593352a9ec30680c69435" && "cursor"
                              }`}
                              onClick={rightClick}
                            />
                          </div>
                        </div>
                        <div className="text_flex">
                          <p className="left_text">Number of Device</p>
                          <p className="right_text">{totalDevices()}</p>
                        </div>
                        <div className="text_flex">
                          <p className="left_text">Number of User</p>
                          <p className="right_text">{totalUsers()}</p>
                        </div>
                        <div className="line"></div>
                        <p className="summary">Add-ons Summary</p>
                        <div ref={dropdown} className="add-ons-list">
                          {addonList.map(({ name, price, count }, i) => (
                            <div className="text_flex" key={`${i}`}>
                              <div className="addon_name_qty">
                                <p className="left_text dark">{name}</p>
                                <div className="right_box">
                                  <img
                                    src={minus}
                                    className={`arr ${
                                      id !== "62a593352a9ec30680c69435" &&
                                      "cursor"
                                    }`}
                                    alt="left"
                                    onClick={() => decreaseAddon(i)}
                                  />
                                  <div className="text_square">
                                    <p>{count}</p>
                                  </div>
                                  <img
                                    src={add}
                                    alt="right"
                                    className={`arr rotate ${
                                      id !== "62a593352a9ec30680c69435" &&
                                      "cursor"
                                    }`}
                                    onClick={() => increaseAddon(i)}
                                  />
                                </div>
                              </div>
                              <div className="right_box">
                                {" "}
                                <p>
                                  {currency}
                                  {getAddonTotal(price, count)}
                                </p>{" "}
                                <span
                                  className="relative cursor"
                                  onClick={() => setShowDropdown(i)}
                                >
                                  <img
                                    src={ellipse}
                                    alt="ellipse"
                                    className="ellipse"
                                  />{" "}
                                  {showDropdown === i && (
                                    <p
                                      class="absolute delete-item"
                                      onClick={() => removeAddon(i)}
                                    >
                                      Delete Item
                                    </p>
                                  )}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="line"></div>
                        <div className="text_flex">
                          <p className="left_text dark">Total Bill</p>
                          <p className="right_text">
                            {currency}
                            {formatCurrency(totalPrice())}
                          </p>
                        </div>

                        <button className="proceed">Proceed to checkout</button>
                      </div>
                    </div>
                  </div>
                )}
              </div>{" "}
            </div>
          </form>
        </FormProvider>
      </div>
    </React.Fragment>
  );
};

export default AddOn;
